import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/img/logo/footer-logo.png";
import footerBG from "../../assets/img/footer/footer-bg.jpg";

const servicesData = [
  {
    heading: "OFF-SET PRINTING",
    services: [
      "Bussiness",
      "Cards",
      "Letter",
      "Heads",
      "Flyers",
      "Brochure",
      "Company",
      "Profile",
      "Folders",
      "Envelops",
      "Stickers",
      "Vouchers",
      "Invitation",
      "Paper",
      "Sleeves",
      "Coupons",
      "Loyalty",
      "Bags",
    ],
  },

  {
    heading: "DIGITAL PRINTING",
    services: [
      "Roll-up Banner",
      "Pop-up Banner",
      "Outdoor Flex",
      "Fence Banners",
      "Flag",
      "Window Sticker",
      "Vinyl",
      "Window Frost",
      "One Way Vision",
      "Canvas",
      "Photo Frames",
    ],
  },
  {
    heading: "SCREEN PRINTING",
    services: [
      "T-Shirt",
      "Mugs",
      "Pens",
      "Diary",
      "PVC Sticker",
      "Customized Gifts",
      "Trophy",
    ],
  },
  {
    heading: "PACKAGING",
    services: [
      "Boxes",
      "Vegitable Tray",
      "Cutlery",
      "Napkin",
      "Flag",
      "Perfumed Wet Wipes",
      "Carft Paper Bags",
      "Plastic Bags",
      "Shawarma Sleeves",
      "Burger Boxes",
      "Fries Sleeve",
      "Stickers",
    ],
  },
  {
    heading: "SIGNAGES",
    services: [
      "Office Door Sign",
      "Acrylic Cutouts",
      "Light Box",
      "Foam Board",
      "Fence Banner",
      "Forex Board",
      "Led Sign",
      "3D Sign",
      "Safety Sign",
    ],
  },
];

const Footer = (props) => {
  const { footerLogo, footerClass } = props;
  return (
    <footer>
      <div
        className={footerClass ? footerClass : "footer__area footer__plr"}
        style={{ backgroundImage: `url(${footerBG})` }}
      >
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-7 mb-70 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="footer__widget footer-col-1">
                <div className="footer__logo">
                  <Link to="/">
                    <img
                      style={{ maxWidth: "250px" }}
                      src={footerLogo ? footerLogo : Logo}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="footer__content">
                  {/* <div className="footer__text">
                    <p>
                      Our approach to itis unique around know work an we know
                      doesn’t work verified factors in play.
                    </p>
                  </div> */}
                  <div className="footer__number-wrapper d-flex align-items-center">
                    <div className="footer__number-icon">
                      <span>
                        <i className="fal fa-phone-alt"></i>
                      </span>
                    </div>
                    <div className="footer__number-info">
                      <span>Have Any Questions?</span>
                      <a href="tel:+971501003877">+971 50 100 3877</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 col-sm-5 mb-70 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="footer__widget footer-col-2">
                <h4 className="footer__title">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      var element = document.getElementById("services-section");
                      if (element) {
                        element.scrollIntoView();
                      }
                    }}
                    to="#"
                  >
                    Services
                  </Link>
                </h4>
                <div className="footer__menu-list">
                  <ul>
                    {servicesData.map((service) => (
                      <li key={service.heading}>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            var element =
                              document.getElementById("services-section");
                            if (element) {
                              element.scrollIntoView();
                            }
                          }}
                          to="#"
                        >
                          {service.heading}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-7 mb-70 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="footer__widget footer-col-4">
                {/* <h4 className="footer__title">
                  <a href="#">Newslatter</a>
                </h4> */}
                <div className="footer__content">
                  <p>
                    PRINTBOX takes pride in our customer-centric approach, and
                    we are committed to delivering exceptional quality Design
                    Print Packaging.
                  </p>
                </div>
                {/* <div className="footer__input">
                                    <form action="#">
                                        <input type="email" placeholder="Your email" />
                                        <button className="subscribe-btn">Subscribe </button>
                                    </form>
                                </div> */}
                <div className="footer__social-box">
                  <span>Social Media:</span>
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-google-plus-g"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-pinterest-p"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright__area copyright__plr black-bg-3">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div
              className="col-md-6 wow animate__slideInUp"
              data-wow-duration="1.1s"
            >
              <div className="copyright__text">
                <span>Copyright Printboxs 2023, All Right Reserved</span>
              </div>
            </div>
            <div
              className="col-md-6 wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <div className="copyright__item text-start text-md-end">
                <span>
                  <a href="/">Home</a>
                </span>
                {/* <span>
                  <a href="#">Cases</a>
                </span> */}
                {/* <span>
                  <a href="#">About</a>
                </span> */}
                <span>
                  <a
                    onClick={(e) => {
                      var element = document.getElementById("services-section");
                      if (element) {
                        element.scrollIntoView();
                      }
                    }}
                    to="/"
                  >
                    services
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
