import React from "react";

const SectionTitle = (props) => {
  const { sectionClass, subTitleClass, titleClass, subTitle, Title, width } =
    props;

  return (
    <div
      className={
        sectionClass ? sectionClass : "feature__section-box text-center pb-40"
      }
    >
      <h4
        className={
          subTitleClass ? subTitleClass : "section-subtitle title-anim"
        }
      >
        {subTitle ? subTitle : "We Best Feature"}
      </h4>
      <h3 className={titleClass ? titleClass : "section-title title-anim"}>
        {Title ? Title : "We Are the Best Printing And Packaging Company."}
      </h3>
    </div>
  );
};

export default SectionTitle;
