import React from "react";
import "./serviceSwiper.css";
import { Swiper, SwiperSlide } from "swiper/react";

const MobileSwiper = ({ images, title, onBgClick }) => {
  const sliderOption = {
    pagination: {
      el: ".mobile-slider-dots",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    loop: true,
    spaceBetween: 30,
    slidesPerView: "2",
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
    },
  };
  return (
    <div onClick={onBgClick} class="service-swiper">
      <h2>{title}</h2>
      <div class="mobile-swiper-wrapper">
        <Swiper {...sliderOption} className="swiper-wrapper">
          {images.map((image) => {
            return (
              <SwiperSlide
                key={image}
                style={{ margin: "auto" }}
                className="swiper-slide"
              >
                <div>
                  <img src={image} />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="testimonial-slider-dots wow animate__fadeInUp mobile-slider-dots"></div>
      </div>
    </div>
  );
};

export default MobileSwiper;
