import React, { useState } from "react";
import { Link } from "react-router-dom";

import Shape from "../../assets/img/service/sv-item-shape-1.png";
import Image from "../../assets/img/service/sv-icon-shape-1.png";
import MobileSwiper from "../MobileSwiper";

const SingleService = (props) => {
  const {
    images,
    services,
    itemClass,
    titleClass,
    descClass,
    shapeImg,
    serviceIcon,
    serviceIconBg,
    Title,
    Description,
    btnText,
    btnURL,
    btnClass,
  } = props;
  const [swiperOpen, setSwiperOpen] = useState(false);
  let minWidth = {};
  if (Title === "OFF-SET PRINTING") {
    // minWidth = { minWidth: "120%", marginLeft: "-10%" };
  }

  return (
    <>
      {swiperOpen && images && (
        <MobileSwiper
          title={Title}
          images={images}
          onBgClick={() => {
            setSwiperOpen(false);
          }}
        />
      )}
      <div
        onClick={() => {
          setSwiperOpen(true && images?.length>0);
        }}
        style={{
          color: "black",
          background:
            "radial-gradient(rgb(255 255 255), rgb(145 152 229 / 0%))",
        }}
        className={
          itemClass
            ? itemClass
            : "service__item service__border-1  p-relative fix"
        }
      >
        <div className="service__shape">
          <img src={Shape ? Shape : shapeImg} alt="" />
        </div>
        <div className="service__icon-wrapper p-relative">
          <div
            style={{ minHeight: "369px", ...minWidth }}
            className="service__icon-bg services-image"
          >
            <img src={serviceIconBg ? serviceIconBg : Image} alt="" />
          </div>
        </div>
        <div className="service__content">
          <h4
            className={
              (titleClass ? titleClass : "service__title title-color-1") +
              " phone-center-text"
            }
          >
            {/* <Link to={`${btnURL ? btnURL : "contact"}`}> */}
              {Title ? Title : "Get Latest Updates"}{" "}
            {/* </Link> */}
          </h4>
          <div
            className="services-height phone-center-text"
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
          >
            {services.map((service) => (
              <h3
                key={service.name}
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  marginLeft: "5px",
                }}
              >
                {service.name}
              </h3>
            ))}
          </div>
          {/* <p className={descClass ? descClass : ''}>{Description ? Description : 'Business building it before the tab providet management, Payroll & Worksite Services full-fledged.'}</p> */}
        </div>
        {/* <div
        className={btnClass ? btnClass : "service__link service__link-color-1"}
      >
        <Link to={`/${btnURL ? btnURL : "contact"}`}>
          {btnText ? btnText : "Read More"}
          <i className="fal fa-arrow-right"></i>
        </Link>
      </div> */}
      </div>
    </>
  );
};

export default SingleService;
