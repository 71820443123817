import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel } from "swiper";
import { Lightbox } from "react-modal-image";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle";

import projectImg1 from "../../assets/img/project/pro1.JPG";
import projectImg2 from "../../assets/img/project/pro2.JPG";
import projectImg4 from "../../assets/img/project/pro4.JPG";
import projectImg6 from "../../assets/img/project/pro6.JPG";
import projectImg7 from "../../assets/img/project/pro7.JPG";
import projectImg8 from "../../assets/img/project/pro8.JPG";
import { useState } from "react";
const Images = [
  projectImg1,
  projectImg2,
  projectImg4,
  projectImg6,
  projectImg7,
  projectImg8,
];
SwiperCore.use([Mousewheel]);

const Project = () => {
  const [imageUrl, setImage] = useState(false);

  const sliderOption = {
    pagination: {
      el: ".project-slider-dots",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    loop: true,
    spaceBetween: 30,
    slidesPerView: "3",
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      991: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <>
      {imageUrl && (
        <Lightbox
          onClose={() => {
            setImage(false);
          }}
          large={imageUrl}
          alt="print!"
        />
      )}
      <div className="project__area project-height pt-90">
        <div className="container">
          <div className="row align-items-end pb-60">
            <div className="col-lg-6 col-md-9">
              <SectionTitle
                sectionClass="project__section-box wow itfadeUp"
                subTitleClass="section-subtitle char-anim"
                titleClass="section-title char-anim"
                subTitle="Our Projects Work"
                Title="One of the best Printing press at your service."
              />
            </div>
            <div className="col-lg-6 col-md-3">
              <div className="project__section-arrow d-flex align-items-center justify-content-end">
                <div className="swiper-button swiper-button-prev">
                  <button>
                    <i className="far fa-arrow-left"></i>
                  </button>
                </div>
                <div className="swiper-button swiper-button-next">
                  <button>
                    <i className="far fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="swiper-container project__active">
            <Swiper
              {...sliderOption}
              mousewheel={true}
              className="swiper-wrapper"
            >
              {Images.map((img) => (
                <SwiperSlide key={img} className="swiper-slide">
                  <div
                    onClick={() => {
                      setImage(img);
                    }}
                    className="project__item"
                  >
                    <div to="/">
                      <img style={{ cursor: "pointer" }} src={img} alt="" />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="project-slider-dots"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Project;
