import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper";

import sliderImg1 from "../../assets/img/slider/slider-1.png";
import shapeImg1 from "../../assets/img/slider/shape-1.png";
import shapeImg2 from "../../assets/img/slider/shape-2.png";
import shapeImg3 from "../../assets/img/slider/shape-3.png";
import shapeImg4 from "../../assets/img/slider/shape-4.png";
import shapeImg5 from "../../assets/img/slider/shape-5.png";
import shapeImg6 from "../../assets/img/slider/shape-6.png";
import shapeImg7 from "../../assets/img/slider/shape-7.png";
import shapeImg8 from "../../assets/img/slider/shape-8.png";
import shapeImg9 from "../../assets/img/slider/shape-9.png";
import shapeImg10 from "../../assets/img/slider/shape-10.png";
import shapeImg11 from "../../assets/img/slider/shape-11.png";
import { FaWhatsapp } from "react-icons/fa";
const Images = ["/freeDel.png", "/about.jpg", "/hero1.jpg", "/hero.jpg"];
const Banner = () => {
  return (
    <div
      style={{ position: "relative" }}
      className="slider__area slider__space blacked-bg slider__plr p-relative z-index fix"
    >
      <img
        alt="print"
        src="/hero1.jpg"
        style={{
          zIndex: -1,
          objectFit: "cover",
          position: "absolute",
          height: "100%",
          width: "100vw",
          inset: 0,
        }}
      />
      <div
        style={{
          opacity: 0.6,
          background: "#000",
          zIndex: -1,
          position: "absolute",
          height: "100%",
          width: "100vw",
          inset: 0,
        }}
      />
      <div
        className="slider__shape-top-1 d-none d-xl-block wow animate__fadeInUp"
        data-wow-duration="1.1s"
      >
        <img src={shapeImg1} alt="" />
      </div>
      <div
        className="slider__shape-top-2 d-none d-xl-block wow animate__fadeInLeft"
        data-wow-duration="1.1s"
      >
        <img src={shapeImg2} alt="" />
      </div>
      <div
        className="slider__shape-top-3 d-none d-xl-block wow animate__fadeInRight"
        data-wow-duration="1.1s"
      >
        <img src={shapeImg3} alt="" />
      </div>
      <div
        className="slider__shape-top-4 d-none d-xl-block wow animate__fadeInRight"
        data-wow-duration="1.1s"
      >
        <img src={shapeImg4} alt="" />
      </div>

      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="slider__section-box">
              <h4
                className="section-subtitle char-anim wow animate__fadeInUp"
                data-wow-duration="1.1s"
              >
                Welcome to Printbox
              </h4>
              <h3
                className="hero-title char-anim wow animate__fadeInUp"
                data-wow-duration="1.1s"
              >
                Innovative Printing Solutions That Elevate Your Brand's Image
              </h3>
            </div>
            <div
              className="slider__content wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <p style={{ color: "white", fontSize:"15px" }} className="char-anim-2">
                At PRINTBOX, we understand that the packaging and printing
                solutions you choose play a pivotal role in shaping your brand
                image and delivering a memorable customer experience. .{" "}
              </p>
            </div>
            <div
              className="slider__btn wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              {/* <Link
                to="https://wa.me/+971501003877"
                className="main-btn tp-btn-hover alt-color"
              >
                <span>
                  <FaWhatsapp style={{ marginRight: "5px" }} size={"20px"} />
                  Get A Quote
                </span>
                <b></b>
              </Link> */}
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 wow animate__fadeInRight"
            data-wow-duration="1.1s"
          >
            <div className="slider__thumb">
              <Swiper
                spaceBetween={30}
                effect={"fade"}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[EffectFade, Pagination, Autoplay]}
                className="mySwiper"
              >
                {Images.map((im) => (
                  <SwiperSlide key={im}>
                    <img
                      style={{
                        width: "calc(100% - 10px)",
                        margin: "5px",
                        borderRadius: "8px",
                        boxShadow: "rgba(200, 200, 200, 0.2) 0px 2px 8px 0px",
                      }}
                      alt="printing"
                      src={im}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
