import React from "react";
import { Link } from "react-router-dom";

import SectionTitle from "../../components/SectionTitle";
import SingleService from "../../components/Service";

import shapeImg1 from "../../assets/img/service/sv-shape-1.png";
import shapeImg2 from "../../assets/img/service/sv-shape-2.png";

import signage1 from "../../assets/img/service/signage1.jpg";
import signage2 from "../../assets/img/service/signage2.jpg";
import signage3 from "../../assets/img/service/signage3.jpg";
import signage4 from "../../assets/img/service/signage4.jpg";
import signage5 from "../../assets/img/service/signage5.jpg";
import signage6 from "../../assets/img/service/signage6.jpg";
import signage7 from "../../assets/img/service/signage7.jpg";

import bagsBoxes from "../../assets/img/service/bagsBoxes.png";
import businesscardsandBro from "../../assets/img/service/businesscardsandBro.png";
import events from "../../assets/img/service/events.png";
import offsetPrinting from "../../assets/img/service/offsetPrinting.png";
import stickers from "../../assets/img/service/stickers.png";
import signage from "../../assets/img/service/signage.png";
import tshirts from "../../assets/img/service/tshirts.png";
import { FaWhatsapp } from "react-icons/fa";

const servicesData = [
  {
    heading: "OFF-SET PRINTING",
    img: offsetPrinting,
    services: [
      { name: "Business cards" },
      { name: "Flyers/Brochure/catalog" },
      { name: "Envelope" },
      { name: "Receipt book/ncr book" },
      { name: "Paper sticker / pvc sticker" },
      { name: "Notebooks" },
      { name: "Poster" },
      { name: "Boxes" },
      { name: "Paper bags/plastic bags" },
      { name: "Sleeves" },
    ],
  },
  {
    heading: "DIGITAL PRINTING",
    img: stickers,
    services: [
      { name: "Rollup banner" },
      { name: "Popup banner" },
      { name: "Flags" },
      { name: "Outdoor Umbrellas" },
      { name: "Tshirt printing" },
      { name: "Flex banner" },
      { name: "Vinyl sticker" },
    ],
  },
  {
    heading: "SCREEN PRINTING",
    img: events, //,
    services: [
      { name: "Mug print" },
      { name: "Tshirt print / embroidery" },
      { name: "Bags print" },
      { name: "Uniform print / embroidery" },
    ],
  },
  {
    heading: "SIGNAGE’S & EXHIBITION STAND",
    img: signage,
    images: [
      signage1,
      signage2,
      signage3,
      signage4,
      signage5,
      signage6,
      signage7,
    ],
    services: [
      { name: "Out door & indoor signs" },
      { name: "Foam board & forex sign" },
      { name: "Acrylic stands" },
      { name: "Emergency sign" },
      { name: "Light boxes" },
      { name: "Name plates" },
      { name: "Led sign" },
    ],
  },
  {
    heading: "CUSTOMIZED GIFT ITEMS",
    img: tshirts,
    services: [
      { name: "Mugs" },
      { name: "Trophy" },
      { name: "Id cards" },
      { name: "Dairy" },
      { name: "Pens" },
      { name: "Pen drives" },
      { name: "Keychain" },
    ],
  },

  {
    heading: "PACKAGING",
    img: bagsBoxes,
    services: [
      { name: "Meal boxes" },
      { name: "Burger box" },
      { name: "Pizza box" },
      { name: "Grill box" },
      { name: "Butter paper" },
      { name: "Shawarma packets" },
      { name: "Fries box" },
    ],
  },
];

const Service = () => {
  return (
    <div
      id="services-section"
      className="service__area service__space service__mlr blacked-bg pt-195 pb-70 p-relative"
    >
      <div className="service__shape-1">
        <img src={shapeImg1} alt="" />
      </div>
      <div className="service__shape-2">
        <img src={shapeImg2} alt="" />
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div
            id="services"
            className="col-xl-6 col-lg-6 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SectionTitle
              sectionClass="service__section-box text-center pb-35 section-title-fixed-width"
              subTitle="Services"
              Title={
                <>
                  Contact Today For <br /> Offset | Digital | Screen Printing |
                  Signages | Gift Items
                </>
              }
            />
          </div>
        </div>
        <div className="row">
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SingleService
              itemClass="service__item service__border-1 p-relative fix"
              titleClass="service__title title-color-1"
              btnClass="service__link service__link-color-1"
              serviceIconBg={servicesData[0].img}
              Title={servicesData[0].heading}
              services={servicesData[0].services}
              Description="Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
              btnURL="https://wa.me/+971501003877"
              btnText={
                <span>
                  <FaWhatsapp style={{ marginRight: "5px" }} size={"20px"} />
                  Get A Quote
                </span>
              }
            />
          </div>
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SingleService
              itemClass="service__item service__border-2 p-relative fix"
              titleClass="service__title title-color-2"
              btnClass="service__link service__link-color-2"
              serviceIconBg={servicesData[1].img}
              Title={servicesData[1].heading}
              services={servicesData[1].services}
              Description="Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
              btnURL="https://wa.me/+971501003877"
              btnText={
                <span>
                  <FaWhatsapp style={{ marginRight: "5px" }} size={"20px"} />
                  Get A Quote
                </span>
              }
            />
          </div>
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SingleService
              itemClass="service__item service__border-3 p-relative fix"
              titleClass="service__title title-color-3"
              btnClass="service__link service__link-color-3"
              serviceIconBg={servicesData[2].img}
              Title={servicesData[2].heading}
              services={servicesData[2].services}
              Description="Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
              btnURL="https://wa.me/+971501003877"
              btnText={
                <span>
                  <FaWhatsapp style={{ marginRight: "5px" }} size={"20px"} />
                  Get A Quote
                </span>
              }
            />
          </div>
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SingleService
              itemClass="service__item service__border-4 p-relative fix"
              titleClass="service__title title-color-4"
              btnClass="service__link service__link-color-4"
              serviceIconBg={servicesData[3].img}
              Title={servicesData[3].heading}
              services={servicesData[3].services}
              images={servicesData[3].images}
              Description="Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
              btnURL="https://wa.me/+971501003877"
              btnText={
                <span>
                  <FaWhatsapp style={{ marginRight: "5px" }} size={"20px"} />
                  Get A Quote
                </span>
              }
            />
          </div>
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SingleService
              itemClass="service__item service__border-5 p-relative fix"
              titleClass="service__title title-color-5"
              btnClass="service__link service__link-color-5"
              serviceIconBg={servicesData[4].img}
              Title={servicesData[4].heading}
              services={servicesData[4].services}
              Description="Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
              btnURL="https://wa.me/+971501003877"
              btnText={
                <span>
                  <FaWhatsapp style={{ marginRight: "5px" }} size={"20px"} />
                  Get A Quote
                </span>
              }
            />
          </div>
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SingleService
              itemClass="service__item service__border-6 p-relative fix"
              titleClass="service__title title-color-6"
              btnClass="service__link service__link-color-6"
              serviceIconBg={servicesData[5].img}
              Title={servicesData[5].heading}
              services={servicesData[5].services}
              Description="Business building it before the tab providet management, Payroll & Worksite Services full-fledged."
              btnURL="https://wa.me/+971501003877"
              btnText={
                <span>
                  <FaWhatsapp style={{ marginRight: "5px" }} size={"20px"} />
                  Get A Quote
                </span>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
